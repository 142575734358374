import { ElementType, ReactNode } from 'react';

import cn from '@/utils/cn';

type Props = {
  children: ReactNode;
  id?: string;
  renderAs?: ElementType;
  className?: string;
};

const Container = ({ children, id, renderAs: Component = 'div', className }: Props) => (
  <Component
    id={id}
    className={cn(
      'w-full px-5 sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg desktop:px-2 xl:max-w-screen-xl',
      className
    )}
  >
    {children}
  </Component>
);

export default Container;
