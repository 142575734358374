import { ReactNode } from 'react';

import cn from '@/utils/cn';
import Link from 'next/link';

type Props = {
  children: ReactNode;
  variant?: 'contained' | 'outlined';
  className?: string;
  color?: 'pink' | 'green' | 'blue';
} & (
  | {
      href: string;
      onClick?: never;
      type?: never;
      renderAs?: never;
    }
  | {
      href?: never;
      onClick: () => void;
      type?: 'button';
      renderAs?: 'button';
    }
  | {
      href?: never;
      onClick?: never;
      type: 'submit';
      renderAs?: 'button';
    }
  | {
      href?: never;
      onClick?: never;
      type?: never;
      renderAs?: 'summary';
    }
);

const Button = ({
  children,
  className: customClassName,
  variant = 'contained',
  color = 'pink',
  type = 'button',
  renderAs: Component = 'button',
  href,
  onClick,
}: Props) => {
  const className = cn(
    'rounded-[10px] px-5 py-[9px] transition-colors border-1 shadow-lg',
    {
      'w-fit mx-auto cursor-pointer outline-none marker:hidden list-none [&::-webkit-details-marker]:hidden':
        Component === 'summary',
      'text-white': variant === 'contained',
      'bg-white hover:text-white': variant === 'outlined',
      'border-pink hover:bg-pink-dark hover:border-pink-dark shadow-pink-light/20': color === 'pink',
      'border-green hover:bg-green-dark hover:border-green-dark shadow-green-light/20': color === 'green',
      'border-blue hover:bg-blue-dark hover:border-blue-dark shadow-blue-light/20': color === 'blue',
      'bg-pink': color === 'pink' && variant === 'contained',
      'bg-green': color === 'green' && variant === 'contained',
      'bg-blue': color === 'blue' && variant === 'contained',
      'text-pink': color === 'pink' && variant === 'outlined',
      'text-green': color === 'green' && variant === 'outlined',
      'text-blue': color === 'blue' && variant === 'outlined',
    },
    customClassName
  );

  return href ? (
    <Link className={className} href={href}>
      {children}
    </Link>
  ) : (
    <Component className={className} type={type} onClick={onClick}>
      {children}
    </Component>
  );
};

export default Button;
