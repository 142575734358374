'use client';

import { MouseEventHandler, useContext, useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import contact from '@/consts/contact';
import anchors from '@/consts/anchors';
import Container from '@/components/Container';
import HamburgerButton from '@/components/HamburgerButton';
import { HamburgerContext } from '@/components/HamburgerProvider';
import cn from '@/utils/cn';

import classes from './Header.module.css';

type MenuItem = {
  id: string;
  name: string;
};

const MenuItemElement = ({
  menuItem,
  onClick,
}: {
  menuItem: MenuItem;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}) => (
  <Link
    key={menuItem.id}
    href={`#${menuItem.id}`}
    className="-mb-2 border-b border-transparent pb-2 font-arsenal text-h3 font-medium text-brown-2 transition-colors hover:border-pink hover:text-pink desktop:font-nunito desktop:text-button"
    onClick={onClick}
  >
    {menuItem.name}
  </Link>
);

const Header = (): JSX.Element => {
  const { isOpened, setIsOpen } = useContext(HamburgerContext);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const element = document.querySelector('#app');
    const handler = (e: Event) => {
      const target = e.target as HTMLDivElement;
      setScroll(target.scrollTop);
    };

    element?.addEventListener('scroll', handler);

    return () => element?.removeEventListener('scroll', handler);
  }, []);

  const menu: MenuItem[] = [anchors.aboutUs, anchors.courses, anchors.gallery, anchors.faq, anchors.contact];

  const contactElement = (
    <>
      <Link
        href={`tel:${contact.phone}`}
        className="-mb-2 flex items-center border-b border-transparent pb-2 text-button leading-[25px] text-pink transition-colors hover:border-pink"
      >
        {contact.formattedPhone}
      </Link>
      {/* <div className="hidden h-5 w-[1px] bg-pink desktop:block" />
      <div className="flex gap-3">
        <Link href="">
          <Image src="/fb.svg" alt="facebook" width={25} height={25} />
        </Link>
        <Link href="">
          <Image src="/instagram.svg" alt="instagram" width={25} height={25} />
        </Link>
        <Link href="">
          <Image src="/yt.svg" alt="youtube" width={25} height={25} />
        </Link>
      </div> */}
    </>
  );

  return (
    <>
      <div className="absolute h-[90px] w-full bg-gray-light" />
      <header
        className={cn(
          'overflow-hidde sticky top-0 z-40 flex h-[90px] w-full justify-center py-5 transition-[background-color,_filter]',
          {
            'bg-white/80 drop-shadow-sm backdrop-blur-md': scroll > 50 && !isOpened,
            'bg-gray-light': isOpened,
          }
        )}
      >
        <Container>
          <div className="relative z-10 flex w-full justify-between">
            <Link href="#start">
              <Image src="/logo.svg" alt="logo" width={188} height={40} />
            </Link>
            <div className="hidden items-center gap-10 desktop:flex">
              <div className="flex items-center gap-6 xl:gap-10">
                {menu.map((menuItem) => (
                  <MenuItemElement key={`${menuItem.id}`} menuItem={menuItem} />
                ))}
                <div className="flex items-center justify-center gap-5">{contactElement}</div>
              </div>
            </div>
            <HamburgerButton />
          </div>
        </Container>
      </header>
      <div
        className={cn(
          'invisible sticky top-[90px] z-30 flex h-0 flex-col bg-gray-light opacity-0 transition-[height]',
          { [classes.openedMenu]: isOpened }
        )}
      >
        <div className="flex min-h-0 flex-1 flex-col items-center overflow-auto py-5">
          <div className="min-h-96 my-auto flex flex-col items-center gap-14 text-center">
            {menu.map((menuItem) => (
              <MenuItemElement
                key={`${menuItem.id}`}
                menuItem={menuItem}
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                  setTimeout(() => document.getElementById(menuItem.id)?.scrollIntoView(), 200);
                }}
              />
            ))}
          </div>
        </div>
        <div className="m-auto h-[1px] w-[90%] bg-pink-light/20 text-pink" />
        <div className="flex justify-between p-5">{contactElement}</div>
      </div>
    </>
  );
};

export default Header;
