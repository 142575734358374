'use client';

import { useState } from 'react';
import Image from 'next/image';
import Lightbox from 'yet-another-react-lightbox';

import cn from '@/utils/cn';

import 'yet-another-react-lightbox/styles.css';
import './Gallery.css';

const pictures = new Array(12).fill('').map((_, i) => ({ src: `/gallery/gallery${i + 1}.jpeg` }));

const getGridArea = (index: number) => {
  if (!index) {
    return 'main1';
  } else if (index === 1) {
    return 'main2';
  } else {
    return '.';
  }
};

const Gallery = () => {
  const [index, setIndex] = useState(-1);

  return (
    <>
      <Image
        className="absolute -right-56 -top-24 hidden desktop:block"
        src="/gallery-bubbles.svg"
        alt="bubbles"
        width={675}
        height={605}
      />
      <div className="gallery relative z-10 flex gap-5 overflow-auto desktop:grid desktop:grid-cols-6 desktop:grid-rows-3">
        <div className="p-[1px] desktop:hidden" />
        {pictures.map((picture, index) => (
          <Image
            key={picture.src}
            style={{ gridArea: getGridArea(index) }}
            className={cn(
              'aspect-square min-w-[320px] cursor-zoom-in rounded-[20px] bg-blue-light object-cover desktop:min-w-0',
              {
                'desktop:w-[400px]': [0, 1].includes(index),
                'desktop:w-[190px]': ![0, 1].includes(index),
              }
            )}
            src={picture.src}
            alt="galeria"
            width={[0, 1].includes(index) ? 400 : 320}
            height={[0, 1].includes(index) ? 400 : 320}
            onClick={() => setIndex(index)}
          />
        ))}
        <div className="p-[1px] desktop:hidden" />
      </div>
      <Lightbox
        className="modal"
        index={index}
        slides={pictures}
        open={index >= 0}
        close={() => setIndex(-1)}
        noScroll={{ disabled: true }}
      />
    </>
  );
};

export default Gallery;
