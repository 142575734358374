import { ReactNode } from 'react';

import cn from '@/utils/cn';

type Props = {
  children: ReactNode;
  className?: string;
};

const Paper = ({ className, children }: Props) => (
  <div className={cn('rounded-[50px] bg-white p-7', className)}>{children}</div>
);

export default Paper;
