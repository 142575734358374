'use client';

import { useContext } from 'react';

import { HamburgerContext } from '@/components/HamburgerProvider';
import cn from '@/utils/cn';

import './HamburgerButton.css';

const HamburgerButton = () => {
  const { isOpened, setIsOpen } = useContext(HamburgerContext);

  return (
    <div className="desktop:hidden">
      <button aria-label="Menu" type="button" className="h-12 w-12 px-2.5 pb-4" onClick={() => setIsOpen((ct) => !ct)}>
        <div className={cn('hamburgerMenu bg-pink before:bg-pink after:bg-pink', { animate: isOpened })} />
      </button>
    </div>
  );
};

export default HamburgerButton;
