import type { Config } from 'tailwindcss';

const typography = {
  h1: ['45px', { fontWeight: 400, lineHeight: '56px' }], // Arsenal
  h2: ['35px', { fontWeight: 400, lineHeight: '44px' }], // Arsenal
  h3: ['24px', { fontWeight: 400, lineHeight: '30px' }], // Arsenal
  subtitle1: ['20px', { fontWeight: 400, lineHeight: '25px' }], // Arsenal
  subtitle2: ['16px', { fontWeight: 400, lineHeight: '20px' }], // Arsenal
  body1: ['15px', { fontWeight: 400, lineHeight: '23px' }], // Nunito Sans
  body2: ['13px', { fontWeight: 400, lineHeight: '20px' }], // Nunito Sans
  helper: ['12px', { fontWeight: 400, lineHeight: '18px' }], // Nunito Sans
  button: ['15px', { fontWeight: 500, lineHeight: '23px' }], // Nunito Sans
} satisfies Exclude<Config['theme'], undefined>['fontSize'];

export default typography;
