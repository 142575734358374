'use client';

import { Fragment, useState } from 'react';
import Image from 'next/image';

import { Course } from '@/consts/courses';
import schedules from '@/consts/schedules';
import Modal from '@/components/Modal';
import cn from '@/utils/cn';

type Props = {
  course: Course;
  scheduleId: 'weekly' | 'oneTime';
};

const ScheduleModal = ({ course, scheduleId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [oneTimeSchedule] = schedules[scheduleId];

  return (
    <>
      <div
        className="cursor-pointer text-button text-pink hover:underline"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Zobacz harmonogram
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        className={cn('w-[800px] overflow-y-scroll pt-16 lg:pt-5', scheduleId !== 'oneTime' ? 'min-h-full' : undefined)}
      >
        <div className="flex w-full flex-col items-center text-center">
          <div className="font-arsenal text-subtitle1 text-pink">
            Kurs {course.name}
            {course.dateTime ? ` • ${course.dateTime}` : ''}
          </div>
          <div className="mt-2.5 max-w-sm font-arsenal text-h3 sm:max-w-none">
            {scheduleId === 'oneTime' ? oneTimeSchedule.title : 'Harmonogram spotkań'}
          </div>
          {scheduleId === 'oneTime' && (
            <div className="mt-2.5 inline text-body2 font-light opacity-70 sm:ml-1">
              {oneTimeSchedule.providers.join(', ')}
            </div>
          )}
          <div className="mt-10 flex w-full flex-col gap-5">
            {schedules[scheduleId].map((item, i) => (
              <Fragment key={`${scheduleId}-${i}`}>
                <div className="flex flex-col items-start gap-5 desktop:flex-row">
                  {item.type === 'oneTime' ? (
                    <>
                      {!!item.topics.length && (
                        <div className="flex w-full grid-cols-2 flex-col gap-x-3 gap-y-5 sm:grid">
                          {item.topics.map((topic, j) => (
                            <div key={topic} className="flex content-start gap-2">
                              <Image src="/check.svg" width={24} height={24} alt="check" className="mt-[3px] max-h-6" />
                              <div className="flex flex-wrap text-left font-arsenal text-subtitle1">{topic}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="whitespace-nowrap font-arsenal text-subtitle1 text-pink">{`${item.week} tydz.`}</div>
                      <div className="flex w-full flex-col items-start gap-2">
                        <div className="flex flex-col whitespace-pre-line text-left font-arsenal text-subtitle1 sm:inline">
                          {item.title}
                          <div className="inline font-nunito text-body2 font-light opacity-70 sm:ml-1">
                            {item.providers.map((provider, i) => (
                              <Fragment key={provider}>
                                <span className="inline whitespace-nowrap">{provider}</span>
                                {i < item.providers.length - 1 ? ', ' : ''}
                              </Fragment>
                            ))}
                          </div>
                        </div>
                        {!!item.topics.length && (
                          <div className="flex w-full grid-cols-2 flex-col gap-x-3 gap-y-1 sm:grid">
                            {item.topics.map((topic, j) => (
                              <div key={topic} className="flex content-start gap-2">
                                <Image
                                  src="/check.svg"
                                  width={16}
                                  height={16}
                                  alt="check"
                                  className="mt-[3px] max-h-4"
                                />
                                <div
                                  className={cn('flex flex-wrap text-left text-body1', {
                                    'font-bold': item.week === 11 && j === item.topics.length - 1,
                                  })}
                                >
                                  {topic}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {i < schedules[scheduleId].length - 1 && <div className="h-[1px] w-full bg-brown bg-opacity-10" />}
              </Fragment>
            ))}
            <div></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ScheduleModal;
