type ScheduleWeek =
  | {
      type: 'oneTime';
      title: string;
      week?: never;
      providers: string[];
      topics: string[];
    }
  | {
      type: 'weekly';
      title: string;
      week: number;
      providers: string[];
      topics: string[];
    };

type Schedule = Record<'oneTime' | 'weekly', ScheduleWeek[]>;

const commonProviders: string[] = [];

const schedules: Schedule = {
  oneTime: [
    {
      type: 'oneTime',
      title: 'Kurs obejmuje skróconą tematykę poniższych zagadnień',
      providers: commonProviders,
      topics: [
        'Rozpoczęcie porodu',
        'Mechanizm porodu',
        'Pozycje wertykalne',
        'Oddech podczas porodu',
        'Karmienie piersią',
        'Pielęgnacja noworodka oraz kąpiel',
      ],
    },
  ],
  weekly: [
    {
      type: 'weekly',
      title: 'Przygotowanie do porodu',
      week: 1,
      providers: commonProviders,
      topics: [
        'Metody oceny ruchów płodu',
        'Przygotowanie ciała do porodu',
        'Plan porodu',
        'Masaż krocza',
        'Torba do porodu',
        'Kiedy jechać do szpitala',
        'Przygotowanie psychiczne (mentalne) do porodu',
        'Relaksacja w ciąży',
      ],
    },
    {
      type: 'weekly',
      title: 'Poród',
      week: 2,
      providers: commonProviders,
      topics: [
        'Przebieg porodu prawidłowego',
        'Metody relaksacji i znaczenie ruchu',
        'Ból porodowy',
        'Sprzęty pomocne podczas porodu',
        'Metody łagaodzenia bolu porodowego',
        'Pozycje porodowe',
        'Oddychanie',
      ],
    },
    {
      type: 'weekly',
      title: 'Połóg',
      week: 3,
      providers: commonProviders,
      topics: [
        'Fizjologia połogu',
        'Cięcie cesarskie',
        'Nieprawidłowości w połogu',
        'Dieta w ciąży i połogu',
        'Stany niepokojące',
      ],
    },
    {
      type: 'weekly',
      title: 'Karmienie piersią',
      week: 4,
      providers: commonProviders,
      topics: [
        'Fizjologia laktacji',
        'Żywienie alternatywne noworodka',
        'Korzyści płynące z karmienia piersią',
        'Pozycje podczas karmienia piersią',
        'Stany niepokojące',
      ],
    },
    {
      type: 'weekly',
      title: 'Noworodek',
      week: 5,
      providers: commonProviders,
      topics: [
        'Pierwsze chwile noworodka po narodzeniu',
        'Stany przejściowe u noworodka',
        'Badania u noworodka',
        'Formalności urzędowe po porodzie',
        'Szczepienia',
      ],
    },
    {
      type: 'weekly',
      title: 'Pielęgnacja i kąpiel noworodka',
      week: 6,
      providers: commonProviders,
      topics: [],
    },
    {
      type: 'weekly',
      title: 'Noworodek okiem fizjoterapeuty dziecięcym',
      week: 7,
      providers: commonProviders,
      topics: ['Rozwój dziecka w pierwszym roku życia', 'Prawidłowe podnoszenie noworodka', 'Napięcie mięśniowe'],
    },
    {
      type: 'weekly',
      title: 'Ciąża, poród, połóg w ujęciu fizjoterapeuty uroginekologicznego',
      week: 8,
      providers: commonProviders,
      topics: ['Mięśnie dna miednicy', 'Ćwiczenia w ciąży i połogu', 'Prawidłowe nawyki uroginekologiczne'],
    },
    {
      type: 'weekly',
      title: 'Polski Bank Komórek Macierzystych',
      week: 9,
      providers: ['(przedstawiciel PBKM Marlena Tarnowska)'],
      topics: ['Pierwsza pomoc noworodka', 'Zakończenie i podsumowanie zajęć'],
    },
  ],
};

export default schedules;
