'use client';

import { useState } from 'react';
// import Link from 'next/link';

import { Course } from '@/consts/courses';
// import Input from '@/components/Input';
// import Checkbox from '@/components/Checkbox';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
// import ModalSuccessContent from '@/components/ModalSuccessContent';
// import ModalFailureContent from '@/components/ModalFailureContent';
import ContactData from '@/components/ContactData';
import cn from '@/utils/cn';
// import cn from '@/utils/cn';

// import { postCourse, CourseFormData } from './Courses.server';

type Props = {
  course?: Course;
};

// enum ModalState {
//   FORM,
//   SUCCESS,
//   FAILURE,
// }

const CourseModal = ({ course }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [modalState, setModalState] = useState(ModalState.FORM);

  // const action = async (formData: FormData) => {
  //   const formValues = Object.fromEntries(formData.entries()) as CourseFormData;
  //   const result = await postCourse(formValues, course);

  //   setModalState(result?.success ? ModalState.SUCCESS : ModalState.FAILURE);
  // };

  return (
    <>
      <Button
        color={course?.color}
        onClick={() => {
          // setModalState(ModalState.FORM);
          setIsOpen(true);
        }}
      >
        {course?.type === 'Warsztaty' ? 'Umów się na warsztaty' : 'Umów się na kurs'}
      </Button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="flex flex-col items-center text-center">
          <div className={cn('font-arsenal text-subtitle1 text-pink', { 'text-blue': course?.color === 'blue' })}>
            Zgłoszenie
          </div>
          <div className="mb-5 mt-2.5 max-w-sm font-arsenal text-h3">
            {course?.type === 'Warsztaty' ? 'Umów się na warsztaty' : 'Umów się na kurs'}
          </div>
          <div className="mb-10 max-w-full text-body1 text-brown-2">
            {`Aby wziąć udział w ${course?.type === 'Warsztaty' ? 'warsztatach' : 'kursie'} zadzwoń lub napisz do nas.`}
            <br />
            Wszelkie dodatkowe informacje wyślemy do Ciebie w wiadomości e-mail.
          </div>
          <ContactData color={course?.color} />
          {/* <form className="w-full" action={action}>
            <div className="flex w-full flex-col gap-0.5">
              <Input name="name" color="blue" placeholder="Imię i Nazwisko" required />
              <Input name="email" color="blue" type="email" placeholder="Adres Email" required />
              <Input name="phone" color="blue" type="tel" placeholder="Numer telefonu" required />
              <div className="mt-5">
                <Checkbox
                  name="aggrement"
                  placeholder={
                    <>
                      Akceptuje{' '}
                      <Link href="/" className="underline">
                        regulamin
                      </Link>{' '}
                      bla bla....
                    </>
                  }
                  required
                />
              </div>
              <div className="mt-10 flex justify-center gap-7">
                <button className="text-button text-pink-light" onClick={() => setIsOpen(false)}>
                  Anuluj
                </button>
                <Button type="submit">Wyślij zgłoszenie</Button>
              </div>
            </div>
          </form> */}
        </div>
        {/* {modalState === ModalState.SUCCESS && <ModalSuccessContent header="Twoje zgłoszenie zostało wysłane!" />}
        {modalState === ModalState.FAILURE && (
          <>
            <ModalFailureContent header="Twoje zgłoszenie nie zostało wysłane!" />
            <div className="mt-10 flex justify-center gap-7">
              <button className="text-button text-pink-light" onClick={() => setIsOpen(false)}>
                Anuluj
              </button>
              <Button onClick={() => setModalState(ModalState.FORM)}>Spróbuj ponownie</Button>
            </div>
          </>
        )} */}
      </Modal>
    </>
  );
};

export default CourseModal;
