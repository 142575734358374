import { extendTailwindMerge } from 'tailwind-merge';
import clsx, { ClassValue } from 'clsx';

import typography from '@/consts/typography';

const customTwMerge = extendTailwindMerge({
  classGroups: {
    typography: Object.keys(typography).map((key) => `text-${key}`),
  },
});

const cn = (...args: ClassValue[]) => customTwMerge(clsx(args));

export default cn;
