'use client';

import { Dispatch, ReactNode, SetStateAction, createContext, useState } from 'react';

type HamburgerContextType = {
  isOpened: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const HamburgerContext = createContext<HamburgerContextType>({
  isOpened: false,
  setIsOpen: () => {},
});

type Props = {
  children: ReactNode;
};

const HamburgerProvider = ({ children }: Props): JSX.Element => {
  const [isOpened, setIsOpen] = useState(false);

  return <HamburgerContext.Provider value={{ isOpened, setIsOpen }}>{children}</HamburgerContext.Provider>;
};

export default HamburgerProvider;
