'use client';

import { ReactNode, useEffect, useId } from 'react';
import { createPortal } from 'react-dom';
import Image from 'next/image';

import Paper from '@/components/Paper';
import cn from '@/utils/cn';

type Props = {
  isOpen: boolean;
  className?: string;
  children: ReactNode;
  onClose: () => void;
};

const Modal = ({ className, isOpen, children, onClose }: Props): JSX.Element => {
  const id = useId();

  useEffect(() => {
    const appElement = document.querySelector('#app');

    if (isOpen) {
      appElement?.setAttribute('inert', '');
    } else {
      appElement?.removeAttribute('inert');
    }
  }, [isOpen]);

  return (
    <>
      {isOpen &&
        createPortal(
          <div
            id={id}
            className="modal fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-blue/30 p-5 transition-[background-color]"
            onClick={(e) => {
              if ((e?.target as { id?: string }).id === id) {
                onClose();
              }
            }}
          >
            <Paper
              className={cn(
                'relative flex h-min max-h-full max-w-full animate-fade-with-scale flex-col items-center overflow-auto p-5 shadow-xl shadow-blue/10 desktop:min-w-[610px] desktop:p-14',
                className
              )}
            >
              <button name="Zamknij" className="absolute right-5 top-5" onClick={onClose}>
                <Image src="/close.svg" alt="close" width={40} height={40} />
              </button>
              {children}
            </Paper>
          </div>,
          document.querySelector('body') as Element
        )}
    </>
  );
};

export default Modal;
